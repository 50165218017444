import Vue from "vue"
import VueRouter from "vue-router"
import Redirect from "@/views/Redirect.vue"
import store from "@/store/index"

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: "*",
		component: () => import("../views/404.vue")
	},
	{
		path: "/",
		name: "Home",
		redirect: "/helper"
	},
	{
		path: "/helper",
		name: "Helper",
		layout: "dashboard",
		component: () => import("../views/Helper.vue"),
		meta: { title: "首页" }
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
		meta: { title: "教程首页" }
	},
	{
		path: "/knowledge",
		name: "Knowledge",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/Knowledge.vue"),
		meta: { title: "知识库" }
	},
	{
		path: "/gptstore",
		name: "gptstore",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/gptstore.vue"),
		meta: { title: "插件市场" }
	},
	{
			path: "/knowledgeDetail/:id",
			name: "KnowledgeDetail",
			layout: "dashboard",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import(/* webpackChunkName: "dashboard" */ "../views/KnowledgeDetail.vue"),
			meta: { title: "知识库详情" }
		},
	{
		path: "/mark_me",
		name: "mark_me",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/mark_me.vue"),
		meta: { title: "思维导图" }
	},
	{
		path: "/ppt",
		name: "ppt",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/ppt.vue"),
		meta: { title: "智能生成PPT" }
	},
	{
		path: "/ai_pdf",
		name: "ai_pdf",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/ai_pdf"),
		meta: { title: "PDF解析" }
	},
	// {
	// 	path: "/voice_clone",
	// 	name: "voice_clone",
	// 	layout: "dashboard",
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "dashboard" */ "../views/voice_clone"),
	// 	meta: { title: "声音克隆" }
	// },
	{
		path: "/ai_write",
		name: "ai_write",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/ai_write"),
		meta: { title: "AI写作" }
	},
	{
		path: "/news",
		name: "News",
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/News.vue"),
		meta: { title: "课程详情" }
	},
	{
		path: "/profile",
		name: "Profile",
		layout: "dashboard",
		component: () => import("../views/Profile.vue"),
		meta: { title: "用户信息", auth: true }
	},
	{
		path: "/role",
		name: "Role",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "角色" },
		children: [
			{
				path: "/",
				name: "RoleIndex",
				layout: "dashboard",
				component: () => import("../views/Role.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "chat",
				name: "RoleChat",
				layout: "dashboard",
				component: () => import("../views/RoleChat.vue"),
				meta: { title: "聊天", auth: true }
			}
		]
	},
	{
		path: "/drawing",
		name: "Drawing",
		component: Redirect,
		redirect: "/",
		meta: { title: "绘画" },
		children: [
			{
				path: "/",
				name: "DrawingIndex",
				layout: "dashboard",
				component: () => import("../views/Drawing.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "canvas",
				name: "DrawingCanvas",
				layout: "dashboard",
				component: () => import("../views/DrawingCanvas.vue"),
				meta: { title: "以文生图" }
			},
			{
				path: "picture",
				name: "DrawingPicture",
				layout: "dashboard",
				component: () => import("../views/DrawingPicture.vue"),
				meta: { title: "以图生图" }
			},
			{
				path: "picture2text",
							name: "DrawingPicture2Text",
							layout: "dashboard",
							component: () => import("../views/DrawingPicture2Text.vue"),
							meta: { title: "以图生文" }
						},
			{
										path: "pictureHD",
										name: "DrawingPictureHD",
										layout: "dashboard",
										component: () => import("../views/DrawingPictureHD.vue"),
										meta: { title: "图像高清修复" }
									},
			{
							path: "pictureface",
							name: "DrawingPictureface",
							layout: "dashboard",
							component: () => import("../views/DrawingPictureface.vue"),
							meta: { title: "AI换脸"}
						},
						{
							path: "pictureArtqr",
							name: "DrawingPictureArtqr",
							layout: "dashboard",
							component: () => import("../views/DrawingPictureArtqr.vue"),
							meta: { title: "艺术二维码" }
						}
		]
	},
	{
		path: "/chat",
		name: "Chat",
		layout: "dashboard",
		component: () => import("../views/Chat.vue"),
		meta: { title: "AI会话", auth: true }
	},
	{
		path: "/recharge",
		name: "Recharge",
		layout: "dashboard",
		component: () => import("../views/Recharge.vue"),
		meta: { title: "充值中心", auth: true }
	},
	{
			path: "/write",
			name: "Write",
			layout: "dashboard",
			component: Redirect,
			redirect: "/",
			meta: { title: "创作" },
			children: [
				{
					path: "create",
					name: "WriteCreate",
					component: () => import("../views/WriteCreate.vue"),
					meta: { title: "生成", auth: true }
				}
			]
		},
	{
		path: "/photo",
		name: "Photo",
		layout: "dashboard",
		component: () => import("../views/DrawingCenter.vue"),
		meta: { title: "作品集", auth: true }
	}
	
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {}
	route.meta.layout = route.layout || parentLayout

	if (route.children) {
		route.children = route.children.map(childRoute => addLayoutToRoute(childRoute, route.meta.layout))
	}
	return route
}

routes = routes.map(route => addLayoutToRoute(route))

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: "smooth"
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.meta.auth === true && !store.state.user.token) {
		store.commit("user/setLoginPopup", true)
	} else {
		next()
	}
})

router.afterEach((to, from) => {})

export default router
